/*!
 * Big Orange Project website
 *
 * Copyright 2024 Ricardo do Canto
 *
 * Licensed under MIT (https://github.com/EnduranceCode/afonsodocanto-website/blob/master/LICENSE)
 *
 */

import '../scss/style.scss';

document.addEventListener('DOMContentLoaded', () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach((el) => {
      el.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }

  /**
   * Script for Bulma Tabs inspired by https://embed.plnkr.co/plunk/Vax8bc
   *
   * URL hash features were added taking inspiration from the tutorial in https://usefulangle.com/post/298/javascript-url-hash
   */
  const tabSystem = {
    init() {
      const tabsMenuItems = [];
      let activeTabsMenuItem;

      document.querySelectorAll('.tabs-menu').forEach((tabMenu) => {
        Array.from(tabMenu.children).forEach((child) => {
          tabsMenuItems.push(child.dataset.target);

          child.addEventListener('click', () => {
            tabSystem.toggle(child.dataset.target);
          });
          if (child.className.includes('is-active')) {
            activeTabsMenuItem = child.dataset.target;
          }
        });

        const tabsMenuItemFromUrl = window.location.hash.substring(1);
        if (tabsMenuItems.includes(tabsMenuItemFromUrl)) {
          activeTabsMenuItem = tabsMenuItemFromUrl;
        }

        tabSystem.toggle(activeTabsMenuItem);
      });

      document.querySelectorAll("[data-button='tab-navigation']").forEach((navigationButton) => {
        navigationButton.addEventListener('click', () => {
          tabSystem.toggle(navigationButton.dataset.target);
        });
      });

      window.addEventListener('hashchange', () => {
        activeTabsMenuItem = window.location.hash.substring(1);

        if (tabsMenuItems.includes(activeTabsMenuItem)) {
          tabSystem.toggle(activeTabsMenuItem);
        }
      });
    },

    toggle(targetId) {
      document.querySelectorAll('.tab-content').forEach((contentElement) => {
        contentElement.style.display = contentElement.id === targetId ? 'block' : 'none';
        document
          .querySelector(`[data-target="${contentElement.id}"]`)
          .classList[contentElement.id === targetId ? 'add' : 'remove']('is-active');
      });

      const updatedUrl = new URL(document.URL);
      updatedUrl.hash = '#' + targetId;
      document.location.href = updatedUrl;

      window.scroll(0, 0);
    },
  };

  // Use script for Bulma Tabs
  tabSystem.init();

  /**
   * Scroll to top function
   *
   * Improved following the sugestion on this Stack Overflow answer: https://stackoverflow.com/a/5298684
   */
  const scrollTopButtons = document.querySelectorAll("[data-button='reload']");

  scrollTopButtons.forEach((button) => {
    button.addEventListener('click', () => {
      history.pushState('', document.title, window.location.pathname + window.location.search);
      window.scroll(0, 0);
    });
  });

  /**
   * Manages the cookies acceptance modal
   */
  const cookiesSystem = {
    init() {
      const cookieName = 'big-orange';
      const cookieModal = document.querySelector('#cookies-modal');
      const button = document.querySelector('#accept-cookies');

      if (this.getCookie(cookieName)) {
        cookieModal.remove('is-active');
      } else {
        cookieModal.classList.add('is-active');

        button.addEventListener('click', () => {
          cookiesSystem.setCookie(cookieName, 'big-orange', 60);
          cookieModal.remove('is-active');
        });
      }
    },

    // Function found on https://www.tabnine.com/academy/javascript/how-to-set-cookies-javascript/
    setCookie(cName, cValue, expDays) {
      let date = new Date();
      date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      document.cookie = cName + '=' + cValue + '; ' + expires + '; sameSite=Lax' + '; path=/';
    },

    // Function found on https://www.tabnine.com/academy/javascript/how-to-set-cookies-javascript/
    getCookie(cName) {
      const name = cName + '=';
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded.split('; ');
      let res;
      cArr.forEach((val) => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      });
      return res;
    },
  };

  cookiesSystem.init();
});
